import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useEffect, useState } from "react";
import { saveDepartamento } from "../../services/DepartamentoService";
import darkTheme from "../../layout/DarkTheme";


const initialState = {
	descripcion: "",
	observaciones: "",
};


const nameItemText = "Departamento";
const itemGender = "M";

//
// #############################################################################
//
const DepartamentoDialog = ({
	provincias, 
	onSave,
	onClose,
	itemCrud,
	actionCrud,
	setMessage,
	setShowMessage,
}) => {
	const [itemCrudLocal, setItemCrudLocal] = useState(initialState);
	const [isDoingSomething, setIsDoingSomething] = useState(false);
	const [valueAutoCompleteProvincia, setValueAutoCompleteProvincia] = useState(itemCrud.provincia);
	const [inputValueAutoCompleteProvincia, setInputValueAutoCompleteProvincia] = useState("");

	useEffect(() => {
    if (actionCrud === "create") {
			setValueAutoCompleteProvincia("");
			setItemCrudLocal(initialState);
		} else {
			setValueAutoCompleteProvincia(itemCrud.provincia);
      setItemCrudLocal(itemCrud);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseDialog = () => {
		onClose();
	};

	const handleChange = (e) => {
		setItemCrudLocal({
			...itemCrudLocal,
			[e.target.name]: e.target.value,
		});
	};

	const handleSave = () => {
		
		if (actionCrud === "create" || actionCrud === "update") {
			if (itemCrudLocal.descripcion.trim() === "") {
				setMessage({
					severity: "error",
					messageText: "Debes ingresar una descripción",
				});
				setShowMessage(true);
				return;
			}

			if (inputValueAutoCompleteProvincia === ""||inputValueAutoCompleteProvincia === null ){
				setMessage({
					severity: "error",
					messageText: "Debes seleccionar una provincia",
				});
				setShowMessage(true);
				return;
			}

		
		}
		save();
	};

	const save = async () => {
		let _itemCrud = {
			idProvincia: valueAutoCompleteProvincia.id,
			descripcion: itemCrudLocal.descripcion,
			observaciones: itemCrudLocal.observaciones,
			usernameUsuarioActualiza: localStorage.getItem("emailUsuario"),
		};
		if (actionCrud !== "create") {
			_itemCrud.id = itemCrudLocal.id;
		}


		setIsDoingSomething(true);
		const response = await saveDepartamento(actionCrud, _itemCrud);
		setIsDoingSomething(false);

		if (response.ok) {
      onSave();
    }

    setMessage({
      messageText: response.messageText,
      severity: response.messageSeverity,
    });
    setShowMessage(true);
	};

	return (
		<>
			<Dialog
				open={true}
				onClose={handleCloseDialog}
				maxWidth="md"
        fullWidth={true}
				scroll="paper"
				aria-labelledby="form-dialog-title"
			>
				        <DialogTitle id="form-dialog-title"
					textAlign={{ xs: "center", sm: "center" }}			
					style={{ background: darkTheme.palette.primary.dark, color: darkTheme.palette.primary.text}}
				>
					{actionCrud === "create"
						? itemGender === "M"
							? "Nuevo"
							: "Nueva"
						: actionCrud === "update"
						? "Editando"
						: actionCrud === "enable"
						? "Habilitando"
						: actionCrud === "read"
						? "Detalle"
						: "Deshabilitando"}{" "}
					{nameItemText}
					<IconButton
						aria-label="close"
						onClick={handleCloseDialog}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				{isDoingSomething && <LinearProgress />}

				<DialogContent dividers>
					<Box sx={{ m: 1, p: 1 }}>
					<Autocomplete
							sx={{ width: "100%", pb: 1 }}
							size="small"
							value={valueAutoCompleteProvincia}
							onChange={(event, newValue) => {
								setValueAutoCompleteProvincia(newValue);
							}}
							inputValue={inputValueAutoCompleteProvincia}
							onInputChange={(event, newInputValue) => {
								setInputValueAutoCompleteProvincia(newInputValue);
							}}
							id="provincia"
							options={provincias}
							renderInput={(params) => (
								<TextField {...params} label="Provincia" />
							)}
							isOptionEqualToValue={(option, value) =>
							option.value === value.value
							}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="descripcion"
							label="Descripción"
							value={itemCrudLocal.descripcion}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
							autoFocus={true}
              inputProps={{ maxLength: 80 }}
						/>

						<TextField
							variant="outlined"
							size="small"
							name="observaciones"
							label="Observaciones"
							value={itemCrudLocal.observaciones}
							onChange={handleChange}
							disabled={actionCrud === "disable" || actionCrud === "enable" || actionCrud === "read"}
							sx={{ width: "100%", pb: 1 }}
              inputProps={{ maxLength: 40 }}
						/>

						{actionCrud !== "create" && (
							<Box
								sx={{
									p: 1,
									color: "SlateGrey",
									border: "0px solid DarkGray",
									borderRadius: "3px",
								}}
							>
								<Typography variant="h8" component="div" gutterBottom>
									Creado: {itemCrudLocal.fechaCreacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Última actualización: {itemCrudLocal.fechaActualizacion}
								</Typography>

								<Typography variant="h8" component="div" gutterBottom>
									Último usuario que actualizó: {itemCrudLocal.nombreUsuario}{" "}
									{itemCrudLocal.apellidoUsuario} ({itemCrudLocal.username})
								</Typography>
							</Box>
						)}
					</Box>

					<Box sx={{ textAlign: "right" }}>
						<Button
							sx={{ mr: 1 }}
							variant="outlined"
							onClick={handleCloseDialog}
							startIcon={<CloseIcon />}
						>									
							Cancelar
						</Button>

						{(actionCrud !== "read") && (	
							<Button
							sx={{ mr: 1, backgroundcolor: "warning" }}
							variant="contained"
							onClick={handleSave}
							startIcon={
								actionCrud === "disable" ? (
									<RemoveCircleOutlineIcon />
								) : actionCrud === "enable" ? (
									<AddCircleOutlineIcon />
								) : (
									<SaveIcon />
								)
							}
						>
							{actionCrud === "disable"
								? "Deshabilitar"
								: actionCrud === "enable"
								? "Habilitar"
								: "Guardar"}
						</Button>
						)}
						</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default DepartamentoDialog;
